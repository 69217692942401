<template>
  <div class="cashBack-wapper">
    <div class="cash-title-wapper hidden-sm-and-up">
      <span>{{ $t("现金返还") }}</span>
    </div>
    <div class="cash-list">
      <div class="cash hidden-xs-only">
        <span>{{ $t("现金") }}</span>
        <span>{{ $t("返还") }}</span>
      </div>
      <swiper :options="swiperOptionThumbs" class="swiper" ref="swiper">
        <swiper-slide v-for="item,index in list" :key="index" >
          <div class="cash-item" @click="toCashBack(item)">
            <span class="item-title" :title="item.name">{{ $t(item.name) }}</span>
            <span class="item-num">{{ item.num }}%</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      swiperOptionThumbs: {
        loop: false,
        slidesPerView: 5,
        centeredSlides: false,
        breakpoints: {
          540: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          768:{
            slidesPerView: 3,
            spaceBetween: 0,
          },
          900: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
          
        },
      },
    }
  },
  props:{
    cashbackList:{
      default:[]
    }
  },
  computed:{
    list(){
      return this.cashbackList.map(item=>{
        let obj = item.vipwater.find(vipwaterItem=>vipwaterItem.gbc_viplvl===this.$store.state.userInfo.level_id)
        return {
          ...item,
          num:obj?obj.gbc_rate:0
        }
      })
      
    }
  },
  methods:{
    /**
     * 跳转页面
     * */ 
    toCashBack(item){
      this.$router.push(`/all-game?tab=${item.id}`)
    }
  }
}
</script>

<style scoped lang="less">
.cashBack-wapper{
  background-color: transparent;
  height: 112px;
  margin-bottom: 20px;
}
.cash{
  width: 139px;
  height: 112px;
  background-image: url(../../../assets/images/cashback-bg.svg);
  background-size: 100% 100%;
  flex-shrink: 0;
  padding-left:18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
  font-size: 16px;
  font-weight: bold;
}
.cash-item{
  background: #252C42;
  border-radius: 8px;
  margin-right: 8px;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  @media (max-width:768px) {
    height: auto;
    padding: 10px;
    margin-right: 4px;
  }
}
.item-title{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item-num{
  color: #FFD801;
  font-weight: bold;
  font-size: 15px;
}
.cash-list{
  display: flex;
  align-items: center;
  overflow: auto;
  background: #111923;
}
.swiper{
  flex: 1;
}
.cash-title-wapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8e939e;
  font-weight: bold;
  margin-bottom: 27px;
}

@media screen and (max-width:768px) {
  .cashBack-wapper{
    height: auto;
    padding: 20px 12px 12px;
    background-color: #111923;
    border-radius: 12px;
  }
}
@media screen and (max-width:540px){
  .cash-item{
    font-size: 12px;
  }
  .item-num{
    font-size: 12px;
  }
}
</style>